const twitter = document.querySelector('#twitter-share')
const facebook = document.querySelector('#facebook-share')


if (twitter) {
    twitter.addEventListener('click', e => {
        e.preventDefault();
        window.open("http://twitter.com/share?text=" + document.title.replace(/\|/g, "-") + "&url=" + window.location.href, '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=700');
        return false;
    })
}

if (facebook) {
    facebook.addEventListener('click', e => {
        e.preventDefault();
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + escape(window.location.href) + "&t=" + document.title, '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=700');
        return false;
    })
}