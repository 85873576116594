import Pikaday from 'pikaday'
import { breakpoints } from '../utils/breakpoints'

const datepickers = document.querySelectorAll('.form__input--date')
if(datepickers) {
	if(window.innerWidth > breakpoints.medium) {
		datepickers.forEach(datepicker => {
			const picker = new Pikaday({
				field: datepicker,
				format: 'DD/MM/YYYY',
				onSelect: function() {
					console.log(this.getMoment().format('dd/mm/yyyy'));
				}
			})

			datepicker.setAttribute('type', 'text')
		})
	}
}
