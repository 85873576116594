const openTrigger = document.querySelector('.icon-burger')
const closeTrigger = document.querySelector('.main-nav__close')
const dropCloseTrigger = document.querySelectorAll('.drop-nav__close')
const dropBackTrigger = document.querySelectorAll('.drop-nav__back')
const mobileNav = document.querySelector('.main-nav')
const dropNavs = document.querySelectorAll('.drop-nav')
const navsWithSub = document.querySelectorAll('[data-sub-nav-target]')
const body = document.body
const htmlElement = document.documentElement
const dropNavSections = document.querySelectorAll('.drop-nav__title')
const searchElement = document.querySelector('.mobile-search')
const searchTrigger = document.querySelector('.icon-search')
const searchClose = document.querySelector('.mobile-search__close')
const page = document.querySelector('.page')

if (openTrigger) {
	openTrigger.addEventListener('click', e => {
		e.preventDefault()

		body.classList.add('nav-open')
		htmlElement.classList.add('nav-open')
		mobileNav.classList.remove('hide')
		mobileNav.classList.add('open')
	})
}

if (closeTrigger) {
	closeTrigger.addEventListener('click', e => {
		e.preventDefault()

		mobileNav.classList.remove('open')
		mobileNav.classList.add('hide')
		body.classList.remove('nav-open')
		htmlElement.classList.remove('nav-open')
	})
}

if (dropCloseTrigger) {
	dropCloseTrigger.forEach(trigger => {
		trigger.addEventListener('click', e => {
			e.preventDefault()

			mobileNav.classList.remove('open')
			mobileNav.classList.add('hide')
			body.classList.remove('nav-open')
			htmlElement.classList.remove('nav-open')

			document.querySelector('.drop-nav.show').classList.remove('show')
		})
	})
}

if (dropBackTrigger) {
	dropBackTrigger.forEach(trigger => {
		trigger.addEventListener('click', e => {
			e.preventDefault()

			document.querySelector('.drop-nav.show').classList.remove('show')
		})
	})
}

if (dropNavSections) {
	dropNavSections.forEach(section => {
		section.addEventListener('click', () => {
			section.parentNode.classList.toggle('open')
		})
	})
}


const dropTriggers = document.querySelectorAll('.main-nav__drop-trigger')

dropTriggers.forEach(item => {
	item.addEventListener('click', e => {
		e.preventDefault()
		item.parentNode.parentNode.querySelector('.drop-nav').classList.add('show')


	})
})

const navItems = document.querySelectorAll('.main-nav__item')

navItems.forEach(item => {
	item.addEventListener('mouseover', e => {
		if (item.querySelector('.drop-nav')) {
			if (!item.querySelector('.drop-nav').classList.contains('show')) {
				if (document.querySelector('.show')) {
					document.querySelector('.show').classList.remove('show')
				}
			}
		} else {
			if (document.querySelector('.show')) {
				document.querySelector('.show').classList.remove('show')
			}
		}
	})
})

// if(dropNavSections) {
// 	dropNavSections.forEach(item => {
// 		item.addEventListener('click', e => {
// 			e.preventDefault()

// 			item.parentNode.classList.toggle('open')
// 		})
// 	})
// }

if (searchTrigger) {
	searchTrigger.addEventListener('click', e => {
		e.preventDefault()

		searchElement.classList.add('open')
		searchElement.querySelector('.form__input').focus()
	})
}

if (searchClose) {
	searchClose.addEventListener('click', e => {
		e.preventDefault()

		searchElement.classList.remove('open')
	})
}

// // document.querySelector('.drop-nav').addEventListener('click', (e) => {
// // 	e.stopPropagation()

// // 	const openNav = document.querySelector('.main-nav__item--with-sub.active')

// // 	if(openNav) {
// // 		toggleNav(openNav)
// // 	}
// // })

// function toggleNav(item) {
// 	const drop = document.querySelector(
// 		`[data-sub-nav="${item.dataset.subNavTarget}"]`
// 	)

// 	if (item.classList.contains('active')) {
// 		item.classList.remove('active')
// 		drop.classList.remove('show')
// 		drop.classList.add('close')
// 		body.classList.remove('nav-open')
// 		body.classList.add('nav-closed')
// 	} else if(!item.classList.contains('active')) {
// 		navsWithSub.forEach(nav => {
// 			const activeDrop = document.querySelector(
// 				`[data-sub-nav="${nav.dataset.subNavTarget}"]`
// 			)

// 			if(nav.classList.contains('active')) {
// 				nav.classList.remove('active')
// 				activeDrop.classList.remove('show')
// 				activeDrop.classList.add('close')
// 				body.classList.remove('nav-open')
// 				body.classList.add('nav-closed')
// 			}

// 			drop.classList.remove('close')
// 			drop.classList.add('show')
// 			item.classList.add('active')
// 			body.classList.remove('nav-closed')
// 			body.classList.add('nav-open')
// 		})
// 	} else {
// 		drop.classList.remove('close')
// 		drop.classList.add('show')
// 		item.classList.add('active')
// 		body.classList.remove('nav-closed')
// 		body.classList.add('nav-open')
// 	}
// }

// function openNav(item) {
// 	const drop = document.querySelector(
// 		`[data-sub-nav="${item.dataset.subNavTarget}"]`
// 	)

// 	if(!item.classList.contains('active')) {
// 		navsWithSub.forEach(nav => {
// 			const activeDrop = document.querySelector(
// 				`[data-sub-nav="${nav.dataset.subNavTarget}"]`
// 			)

// 			if(nav.classList.contains('active')) {
// 				nav.classList.remove('active')
// 				activeDrop.classList.remove('show')
// 				activeDrop.classList.add('close')
// 				body.classList.remove('nav-open')
// 				body.classList.add('nav-closed')
// 			}

// 			drop.classList.remove('close')
// 			drop.classList.add('show')
// 			item.classList.add('active')
// 			body.classList.remove('nav-closed')
// 			body.classList.add('nav-open')
// 		})
// 	} else {
// 		drop.classList.remove('close')
// 		drop.classList.add('show')
// 		item.classList.add('active')
// 		body.classList.remove('nav-closed')
// 		body.classList.add('nav-open')
// 	}
// }

// if(dropNavs) {
// 	dropNavs.forEach(nav => {
// 		const navBack = nav.querySelector('.drop-nav__back')
// 		const navClose = nav.querySelector('.drop-nav__close')

// 		if (navBack) {
// 			navBack.addEventListener('click', e => {
// 				e.preventDefault()

// 				nav.classList.remove('show')
// 				nav.classList.add('close')

// 				navsWithSub.forEach(item => {
// 					if (item.classList.contains('active')) {
// 						item.classList.remove('active')
// 					}
// 				})
// 			})
// 		}

// 		if (navClose) {
// 			navClose.addEventListener('click', e => {
// 				e.preventDefault()

// 				nav.classList.remove('show')
// 				nav.classList.add('close')
// 				mobileNav.classList.remove('open')
// 				mobileNav.classList.add('hide')
// 				body.classList.remove('nav-open')

// 				navsWithSub.forEach(item => {
// 					if (item.classList.contains('active')) {
// 						item.classList.remove('active')
// 					}
// 				})
// 			})
// 		}
// 	})
// }


window.addEventListener('scroll', () => {
	const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
	const navElement = document.querySelector('.main-nav')
	const headerHeight = document.querySelector('.header') ? document.querySelector('.header').clientHeight : null

	if (document.body.clientWidth >= 768 && navElement) {
		if (scrollTop >= headerHeight) {
			navElement.classList.add('fixed')

		} else if (navElement.classList.contains('fixed')) {
			navElement.classList.remove('fixed')
		}

	}
});
