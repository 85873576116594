import * as jQuery from "jquery";
import "jquery-validation"
import "jquery-validation-unobtrusive"
import * as helpers from "../apis/helpers";
import { loadScreen } from "../utils/loader";


(function (d, form, $) {
    if (!form) {
        return;
    }

    var customErrorClass = "form__input--error";
    var customValidClass = "form__input--success";

    $.validator.setDefaults({
        errorClass: "form__input--error",
        validClass: "form__input--valid",
        highlight: function (el, errorClass, validClass) {
            $(el).removeClass(validClass).removeClass(validClass).addClass(customErrorClass).removeClass(customValidClass);

        },
        unhighlight: function (el, errorClass, validClass) {
            $(el).removeClass(errorClass).removeClass(validClass).removeClass(customErrorClass).addClass(customValidClass);
        },
        submitHandler: function (f) {
            loadScreen($(form), 'submit');
            f.submit();
        },
        invalidHandler: function (f) {

        },
    })

})(document, document.getElementById("split-form"), jQuery)