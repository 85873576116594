import $ from "jquery";
import * as helpers from "../apis/helpers";

const branchId = $("#branchReviews").data("branchid");  //Gets branch ID to obtain reviews for
const appEnv = $("#branchReviews").data("env");
const pageSize = 2; //Sets page size for reviews

$(document).ready(function () {
    initialiseBranchReviews();
});

/**
 * Function to obtain branch reviews from the Yext API
 * @param {any} branchId - The ID of the requested branch
 * @param {any} env - The current app envrionment
 * @returns {any} - JSON object containing branch reviews from Yext
 */
function getBranchReviews(branchId, env) {
    if (branchId != "") {
        var requestUrl = helpers.getApiUrl(env) + "/api/locations/reviews/" + branchId + "/";

        return $.ajax({
            type: "GET",
            url: requestUrl,
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        });
    };
}

/**
 * Initialises reviews component with the 2 latest reviews
 * 
 * */
function initialiseBranchReviews() {
    if ($("#branchReviews").length) {   //Checks if reviews element exists
        getBranchReviews(branchId, appEnv).done(function (result) {
            if (result.response.count > 0) {
                $("#branchReviews").show();
                setReviewsCache(result.response);   //Sets reviews JSON into cache for easy access in pagination
                $("#reviewsAvgRating").html(Number(result.response.averageRating).toFixed(1));  //Sets the average rating in the components
                $("#reviewsAvgStars").html(getReviewsStars(result.response.averageRating) + `<br /> based on <a href="">${result.response.count} reviews</a>`); //Sets the average star rating
                for (var i = 0; i < pageSize; i = i + 1) {
                    $("#reviewsList").append(generateReview(result.response.reviews[i]));   //Append reviews cards to the list
                };
                if (result.response.count > pageSize) {
                    resetPagination(result.response.count, 1);  //Adds pagination and onclick event handler to the buttons
                    sessionStorage.setItem("lastReviewsPage", Math.ceil(result.response.count / pageSize)); //Sets the last page into session
                };
            }
        });
    };
}

/**
 * Fuction to generate a review card with response
 * @param {any} review - The review to generater a card from
 * @returns {any} - A review card in HTML form
 */
function generateReview(review) {
    const publishDate = new Date(review.publisherDate);

    var reviewContent = `
<li class="reviews__item">
    <div class="reviews__item__meta">
        <p class="reviews__title">${review.authorName}</p>
        <p class="reviews__date">${publishDate.getDate() + getDateSuffix(publishDate.getDate()) + " " + publishDate.toLocaleString("en-GB", { month: "long" }) + " " + publishDate.getFullYear() }</p>
    </div>
    <div class="reviews__item__content">
        <div class="reviews__item__rating">${getReviewsStars(review.rating)}</div>
        <p>${review.content}</p>
`;

    if (review.comments.length > 0) {
        for (var i = 0; i < review.comments.length; i = i + 1) {
            const commentPublishDate = new Date(review.comments[i].publisherDate);

            reviewContent = reviewContent + `
<div class="reviews__response">
    <div class="reviews__item__meta">
        <img src="${helpers.getAssetsUrl()}/img/logo.svg" alt="Dignity" />
    </div>
    <div class="reviews__item__content">
        <p class="reviews__title">Response from ${review.comments[i].authorName}</p>
        <p class="reviews__date">${commentPublishDate.getDate() + getDateSuffix(commentPublishDate.getDate()) + " " + commentPublishDate.toLocaleString("en-GB", { month: "long" }) + " " + commentPublishDate.getFullYear()}</p>
        <p>${review.comments[i].content}</p>
    </div>
</div>
`;
        }
    }

    reviewContent = reviewContent + `</div></li>`;

    return reviewContent;
};

/**
 * Function to genrate the pagination object for the reviews componetns
 * @param {any} count - The count of all reviews for the branch
 * @param {any} currentPage - The current page of reviews being shown
 * @return {any} - The HTML for the pagination which highlights the current page of reviews
 */
function renderPagination(count, currentPage) {
    const pages = Math.ceil(count / pageSize);

    var pagination = `
<li>
    <div id="reviewsPagination" class="pagination">
        <a id="reviewsFirst" class="pagination__button" href="">First</a>
         <div class="pagination__body">
            <a id="reviewsLeft" class="pagination__block" href=""><i class="icon-left-chevron"></i></a>`;

    for (var i = 1; i <= pages; i = i + 1) {
        pagination = pagination + `<a class="pagination__block ${i == currentPage ? "pagination__block--active" : ""}" href="">${i}</a>`;
    };

    pagination = pagination + `
            <a id="reviewsRight" class="pagination__block" href=""><i class="icon-right-chevron"></i></a>
        </div>
        <a id="reviewsLast" class="pagination__button" href="">Last</a>
    </div>
</li>
`;

    return pagination;
};

/**
 * Function to obtain more reviews for a branch form cache
 * @param {any} page - The requested page for reviews
 */
function getMoreBranchReviews(page) {
    const result = getReviewsCache();   //Obtains reviews from cache
    $("#reviewsList").empty();  //Empties the currently shown cards and pagination buttons
    const start = (parseInt(page) - 1) * pageSize;  //Gets the current index for the reviews array
    const skip = start + pageSize > result.reviews.length ? result.reviews.length : start + pageSize;   //Gets the end value for the loop
    for (var i = start; i < skip; i = i + 1) {
        $("#reviewsList").append(generateReview(result.reviews[i]));    //Adds reviews cards based on position in the array
    };
    resetPagination(result.count, page);    //Re-adds the pagination buttons to the component
};

/**
 * Function to derive the date suffix for a given day
 * @param {any} day - The requested day
 * @returns {any} - The day with the added suffix
 */
function getDateSuffix(day) {
    var suffix = "";

    switch (day) {
        case 1:
        case 21:
        case 31:
            suffix = "st";
            break;

        case 2:
        case 22:
            suffix = "nd";
            break;

        case 3:
        case 23:
            suffix = "rd";
            break;

        default:
            suffix = "th";
            break;
    }

    return suffix;
};

/**
 * Function to get the star rating for the specified numeric rating
 * @param {any} rating  - The numeric rating from Yext
 * @returns {any} - HTML star rating to render to screen
 */
function getReviewsStars(rating) {
    var stars = ``;

    stars = stars + `<img src="${helpers.getAssetsUrl() + "/img/" + (rating >= 1 ? "star-full" : rating > 0.5 ? "star-light-grey-half" : "star-empty")}.svg" />`;
    stars = stars + `<img src="${helpers.getAssetsUrl() + "/img/" + (rating >= 2 ? "star-full" : rating > 1.5 ? "star-light-grey-half" : "star-empty")}.svg" />`;
    stars = stars + `<img src="${helpers.getAssetsUrl() + "/img/" + (rating >= 3 ? "star-full" : rating > 2.5 ? "star-light-grey-half" : "star-empty")}.svg" />`;
    stars = stars + `<img src="${helpers.getAssetsUrl() + "/img/" + (rating >= 4 ? "star-full" : rating > 3.5 ? "star-light-grey-half" : "star-empty")}.svg" />`;
    stars = stars + `<img src="${helpers.getAssetsUrl() + "/img/" + (rating == 5 ? "star-full" : rating > 4.5 ? "star-light-grey-half" : "star-empty")}.svg" />`;

    return stars;
};

/**
 * Sets the reviews JSON array into session storage as a string for easy access
 * @param {any} reviews - The array of reviews from Yext
 * @returns {boolean} - Returns true once complete
 */
function setReviewsCache(reviews) {
    sessionStorage.setItem("branchReviewsCache", JSON.stringify(reviews));
    return true;
};

/**
 * Gets the reviews cache from session storage
 * @returns {any} - Returns reviews as a JSON object
 * */
function getReviewsCache() {
    if (sessionStorage.getItem("branchReviewsCache")) {
        return JSON.parse(sessionStorage.getItem("branchReviewsCache"));
    }
    else {
        return false;
    }
};

/**
 * Regenerates pagination for the component and adds the event handlers for the buttons
 * @param {any} count - The number of reviews
 * @param {any} currentPage - The current page of reviews
 */
function resetPagination(count, currentPage) {
    $("#reviewsList").append(renderPagination(count, currentPage));
    const page = sessionStorage.getItem("lastReviewsPage");
    $("#reviewsFirst").click(function (e) {
        e.preventDefault();
        getMoreBranchReviews(1);
        $(".pagination__page").first().addClass("pagination__block--active");
    });
    $("#reviewsLast").click(function (e) {
        e.preventDefault();
        getMoreBranchReviews(page);
        $(".pagination__page").last().addClass("pagination__block--active");
    });
    $("#reviewsLeft").click(function (e) {
        e.preventDefault();
        const prevPage = currentPage - 1 <= 1 ? 1 : currentPage - 1;
        getMoreBranchReviews(prevPage);
    });
    $("#reviewsRight").click(function (e) {
        e.preventDefault();
        const nextPage = currentPage + 1 >= page ? page : currentPage + 1;
        getMoreBranchReviews(nextPage);
    });
    $(".pagination__block").click(function (e) {
        e.preventDefault();
        if (!isNaN(parseInt(this.innerHTML))) {
            getMoreBranchReviews(this.innerHTML);
            $(this).addClass("pagination__block--active");
        };
    });
};
