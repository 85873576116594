import $ from "jquery";
const accordions = document.querySelectorAll('.accordion')

if(accordions) {
	accordions.forEach(accordion => {
        const accordionItems = accordion.querySelectorAll('.accordion__item:not(.accordion__item--sec)')

        accordionItems.forEach((accordionItem, index) => {
            const accordionTrigger = accordionItem.querySelector('.accordion__title')

            accordionTrigger.addEventListener('click', () => {
                if (accordion.classList.contains("accordion--single-view")) {
					accordionItem.classList.toggle('accordion__item--open')
					accordionItems.forEach(accordionItem2 => accordionItem !== accordionItem2 ? accordionItem2.classList.remove("accordion__item--open") : null);
				} else {
					accordionItem.classList.toggle('accordion__item--open')
				}
			})
		})
	})
}

const accordionFooter = document.querySelectorAll('.footer__accordion');

if(accordionFooter) {
	accordionFooter.forEach(accordion => {
        const accordionItems = accordion.querySelectorAll('.footer__accordion__item:not(.accordion__item--sec)')

        accordionItems.forEach((accordionItem, index) => {
            const accordionTrigger = accordionItem.querySelector('.footer__accordion__title')

            accordionTrigger.addEventListener('click', () => {
                if (accordion.classList.contains("footer__accordion--single-view")) {
					accordionItem.classList.toggle('footer__accordion__item--open')
					accordionItems.forEach(accordionItem2 => accordionItem !== accordionItem2 ? accordionItem2.classList.remove("accordion__item--open") : null);
				} else {
					accordionItem.classList.toggle('footer__accordion__item--open')
				}
			})
		})
	})
}

$(document).ready(function () {
    const accordionsSec = document.querySelectorAll('.accordionsec');

    if (accordionsSec) {
        accordionsSec.forEach(accordionSec => {
            const accordionSecItems = accordionSec.querySelectorAll('.accordion__item')

            accordionSecItems.forEach((accordionSecItem, index) => {
                const accordionSecTrigger = accordionSecItem.querySelector('.accordion__title')

                accordionSecTrigger.addEventListener('click', () => {
                    if (accordionSec.classList.contains("accordion--single-view")) {
                        accordionSecItem.classList.toggle('accordion__item--secopen')
                        accordionSecItems.forEach(accordionSecItem2 => accordionSecItem !== accordionSecItem2 ? accordionSecItem2.classList.remove("accordion__item--secopen") : null);
                    } else {
                        accordionSecItem.classList.toggle('accordion__item--secopen')
                    }
                })
            })
        })
    }
})



