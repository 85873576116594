// Vendor
import Siema from 'siema';

export default class Carousel {

	constructor(config) {
		if(config.prevElement && config.nextElement) {
			this.nextArrow = config.nextElement;
			this.prevArrow = config.prevElement;

			this.nextArrow.addEventListener("click", this.next.bind(this));
			this.prevArrow.addEventListener("click", this.prev.bind(this));
		}

		if(config.dotsWrapper) {
			this.dots = config.dotsWrapper;
        }

        if (config.itemPosValue) {
            config.itemPosValue.innerHTML = 1;
        }

		this.slideCount = config.itemContainer.children.length;
		
		// Add the amount of dots needed from the first child of the dots container
		if(config.dotsWrapper) {
			for(let i = 1; i < this.slideCount; i++) {
				let newDot = this.dots.children.item(0).cloneNode();
				this.dots.appendChild(newDot);
				this.dots.children.item(i).addEventListener("click", (e) => {
					this.jump(i);
				});
			}

			// Add event listener to first dot
			this.dots.children.item(0).addEventListener("click", (e) => {
				this.jump(0);
			});
		}

		// Set the first dot to active
		this.currentSlide = 0;
		this.realCurrentSlide = this.currentSlide;
		this.newestSlide = config.perPage - 1;

		if(config.dotsWrapper) {
			this.dots.children.item(this.currentSlide).classList.add("active");
		}
	
		// While the carousel has more per page than items that exist, duplicate all the children
		while(parseInt(config.perPage) >= config.itemContainer.children.length) {
			for(let i = 0; i < this.slideCount; i++) {
				let dupe = config.itemContainer.children.item(i).cloneNode(true);
				config.itemContainer.appendChild(dupe);
			}
		}

		this.realSlideCount = config.itemContainer.children.length;

		this.siemaInstance = new Siema({
			selector: config.itemContainer,
			loop: true,
			duration: 500,
			easing: 'ease-in-out',
			perPage: {
				0: config.onMobile,
				427: config.onTablet ? parseInt(config.onTablet) : parseInt(config.perPage),
				1025: parseInt(config.perPage)
			},
			onChange: () => { this.onChange.bind(this)(config); }
		});
	}

	onChange(config) {
        this.realCurrentSlide = this.siemaInstance.currentSlide < 0 ? this.siemaInstance.currentSlide + this.realSlideCount : this.siemaInstance.currentSlide;

		let lazyImg;

		if (this.newestSlide < this.siemaInstance.innerElements.length) {
			lazyImg = this.siemaInstance.innerElements[this.newestSlide].querySelectorAll('.lazy') ?
				this.siemaInstance.innerElements[this.newestSlide].querySelectorAll('.lazy') :
				null;

			lazyImg.forEach(function (image) {
				if (image != null) {
					if (image.classList.contains('lazy')) {
						image.classList.remove('lazy');
						if (!image.getAttribute('src')) {
							let slideLazyAlt = image.dataset.src;
							image.src = slideLazyAlt;
						}
					}
				}
			})

		}

		if(config.dotsWrapper) {
			this.dots.children.item(this.currentSlide).classList.remove("active");
			this.currentSlide = (this.dots.children.length + this.siemaInstance.currentSlide) % this.slideCount;
			this.dots.children.item(this.currentSlide).classList.add("active");
        }

        if (config.itemPosValue) {
            this.currentSlide = (this.siemaInstance.currentSlide);
			config.itemPosValue.innerHTML = this.currentSlide + 1;
        }
	}

	jump(i) {
		// Need to do it this way because of the potential of duplicated slides
		var distance = i - this.currentSlide;

		for(let a = 0; a < Math.abs(distance); a++) {
			if (distance < 0) {
				this.newestSlide--;

				if (this.newestSlide < 0) {
					this.newestSlide = this.siemaInstance.innerElements.length - 1;
				}
				this.siemaInstance.prev();
			} else {
				this.newestSlide++;
				this.siemaInstance.next();
			}
		}
	}

	next(e) {
		this.newestSlide++;
		this.siemaInstance.next();
	}

	prev(e) {
		this.newestSlide--;

		if (this.newestSlide < 0) {
			this.newestSlide = this.siemaInstance.innerElements.length - 1;
		}
		this.siemaInstance.prev();
	}

}