// Polyfills
require('objectFitPolyfill');
import 'nodelist-foreach-polyfill';
import 'element-closest';
import 'intersection-observer';
import './polyfills/stickyfill';

// Vendor
import rangeSlider from 'rangeslider-pure'
import ProgressBar from 'progressbar.js'

// Apis
//import './apis/yext-answers';

// Components
import './components/accordion';
import Carousel from './components/carousel';
import './components/nav';
import './components/charts';
import './components/comparison-table';
import './components/basket';
import './components/copy';
import './components/sizes';
import './components/datepicker';
import './components/filters';
import './components/tabs';
import './components/maps';
import './components/modals';
import './components/multi-function-button';
import './components/progress-bar';
import './components/scrollTo';
import './components/side-nav';
import './components/sticky-ctas';
import './components/switch';
import './components/tables';
import './components/team';
import './components/toggle';
import './components/top';
//import './components/forms';
import './components/window';
//import './components/charity';
//import './components/donations';
//import './apis/google-maps';
import './forms/contactform';
import './components/search';
import './components/breadcrumbs';
import './components/banners';
import './components/iframe';
import './components/branch';
import './components/alpha-search';


//Utils
import './utils/lazy';

// Initialise
objectFitPolyfill();


// Target the main.css file
var cssURL = document.querySelector("link[href*='main.']");

var styleSheet = null;

for (var i = 0; i < document.styleSheets.length; i++) {
	if (document.styleSheets[i].href && document.styleSheets[i].href.match(/main.[a-zA-Z0-9]*[.]*css/)) {
		styleSheet = document.styleSheets[i];
	}
}

if (styleSheet != null) {
	genCarousel();
	genRangeSliders();
} else {
	cssURL.addEventListener('load', function () {
		genCarousel();
		genRangeSliders();
	}, false);
}

function genCarousel() {
	// Setup Carousels
	var carouselElements = document.querySelectorAll(".carousel");
	var cardGridCarousels = document.querySelectorAll(".card-grid--carousel");
	let carouselItems = document.querySelectorAll(".carousel__items");

	for (let i = 0, len = carouselItems.length; i < len; i++) {
		carouselItems[i].style.display = 'block';
	}

	for (var i = 0; i < carouselElements.length; i++) {
		var carouselElement = carouselElements[i];
		var mobileItems = 1;

		if (carouselElement.classList.contains("carousel--comparison-table")) {
			mobileItems = 2;
		} else if (carouselElement.classList.contains("carousel--team")) {
			mobileItems = 2;
		} else if (carouselElement.classList.contains("carousel--overflow")) {
			mobileItems = 3;
		}

		var carousel = new Carousel({
			itemContainer: carouselElement.querySelector(".carousel__items"),
			nextElement: carouselElement.querySelector(".carousel__arrow--right") ? carouselElement.querySelector(".carousel__arrow--right") : null,
			prevElement: carouselElement.querySelector(".carousel__arrow--left") ? carouselElement.querySelector(".carousel__arrow--left") : null,
			dotsWrapper: carouselElement.querySelector(".carousel__dots") ? carouselElement.querySelector(".carousel__dots") : null,
			perPage: carouselElement.getAttribute("data-items-per-page") ? carouselElement.getAttribute("data-items-per-page") : 1,
			onTablet: carouselElement.getAttribute("data-items-at-tablet") ? carouselElement.getAttribute("data-items-at-tablet") : null,
			onMobile: mobileItems,
			itemPosValue: carouselElement.querySelector(".carousel-item__position") ? carouselElement.querySelector(".carousel-item__position") : null

		});
	}

	if (window.innerWidth <= 500) {
		for (var i = 0; i < cardGridCarousels.length; i++) {
			var carouselElement = cardGridCarousels[i];
			var mobileItems = 1;

			var carousel = new Carousel({
				itemContainer: carouselElement,
				nextElement: carouselElement.querySelector(".card-grid__arrow--right") ? carouselElement.querySelector(".card-grid__arrow--right") : null,
				prevElement: carouselElement.querySelector(".card-grid__arrow--left") ? carouselElement.querySelector(".card-grid__arrow--left") : null,
				dotsWrapper: carouselElement.querySelector(".card-grid__dots") ? carouselElement.querySelector(".card-grid__dots") : null,
				perPage: carouselElement.getAttribute("data-items-per-page") ? carouselElement.getAttribute("data-items-per-page") : 1,
				onTablet: carouselElement.getAttribute("data-items-at-tablet") ? carouselElement.getAttribute("data-items-at-tablet") : null,
				onMobile: 1
			});
		}
	}
}

// Range slider
function genRangeSliders() {
	const rangeSliders = document.querySelectorAll('.form__range')

	if (rangeSliders) {
		rangeSliders.forEach(slider => {
			rangeSlider.create(slider, {
				onInit: () => {
					if (slider.dataset.tooltip != 'false') {
						const figure = document.createElement('span')
						figure.classList.add('rangeSlider__tooltip')

						if (slider.dataset.tooltip == 'bottom') {
							figure.classList.add('rangeSlider__tooltip--bottom')
						}

						figure.innerHTML = slider.value
						slider.parentNode.querySelector('.rangeSlider__handle').appendChild(figure)
					}
				},
				onSlide: (position, value) => {
					if (slider.dataset.tooltip != 'false') {
						slider.parentNode.querySelector('.rangeSlider__handle span').innerHTML = slider.value
					}
				}
			})
		})
	}
}
