import $ from 'jquery';

document.addEventListener("DOMContentLoaded", function (event) {
    if ($(".search-results").length) {
        buildSearchTypeFilters();
        sessionStorage.setItem("searchCurrentPage", "1");
        callGoogleAPI(1, false);
        document.getElementById("resultsSort").options[0].selected = "selected";
        $(".header__inline-search__input").val(sessionStorage.getItem("searchTerms"));
    }
});

$(".header__inline-search").submit(function () {
    sessionStorage.setItem("searchTerms", $(".header__inline-search__input").val());
});

$("#mobile-search").submit(function () {
    sessionStorage.setItem("searchTerms", $("#mobile-search-input").val());
});

$("#search-cta").submit(function () {
    sessionStorage.setItem("searchTerms", $("#search-cta-input").val());
});

/*Functions for pagination first/last buttons*/
$("#pageFirst").click(function (e) {
    e.preventDefault();
    sessionStorage.setItem("searchCurrentPage", 1);
    resetContentCards();
    resetActivePagination();
    callGoogleAPI(1, true);
    $(".pagination__page").first().addClass("pagination__block--active");
});

$("#pageLast").click(function (e) {
    e.preventDefault();
    var page = sessionStorage.getItem("searchTotalPages");
    resetContentCards();
    resetActivePagination();
    if (page <= 90) {   //Google Custom Search only allows max 100 results regardless of total results: https://developers.google.com/custom-search/json-api/v1/using_rest#response_data
        callGoogleAPI(page, true);
        $(".pagination__page").last().addClass("pagination__block--active");
        sessionStorage.setItem("searchCurrentPage", page);
    }
    else {
        callGoogleAPI(90, true);
        $(".pagination__page").last().addClass("pagination__block--active");
        sessionStorage.setItem("searchCurrentPage", 90);
    };
});

/*Functions for pagination prev/next arrows*/
$("#pagePrev").click(function (e) {
    e.preventDefault();
    var currentPage = sessionStorage.getItem("searchCurrentPage");
    var prevPage = ((currentPage - 1) * 10) + 1 - 10;
    var newPage = parseInt(currentPage) - 1;
    resetContentCards();
    resetActivePagination();
    var pageNumbers = document.getElementsByClassName("pagination__page");
    if (currentPage > 1) {
        callGoogleAPI(prevPage, true);
        sessionStorage.setItem("searchCurrentPage", newPage);
        for (let i = 0; i < pageNumbers.length; i = i + 1) {
            let pageNumber = pageNumbers[i];
            if (parseInt(pageNumber.innerHTML) === newPage) {
                pageNumber.classList.add("pagination__block--active");
            }
        };
    }
    else {
        callGoogleAPI(1, true);
        sessionStorage.setItem("searchCurrentPage", 1);
        for (let i = 0; i < pageNumbers.length; i = i + 1) {
            let pageNumber = pageNumbers[i];
            if (parseInt(pageNumber.innerHTML) === 1) {
                pageNumber.classList.add("pagination__block--active");
            }
        };
    }
});

$("#pageNext").click(function (e) {
    e.preventDefault();
    var currentPage = sessionStorage.getItem("searchCurrentPage");
    var nextPage = ((currentPage - 1) * 10) + 1 + 10;
    var newPage = parseInt(currentPage) + 1;
    resetContentCards();
    resetActivePagination();
    var pageNumbers = document.getElementsByClassName("pagination__page");
    if (currentPage < 10) {
        callGoogleAPI(nextPage, true);
        sessionStorage.setItem("searchCurrentPage", newPage);
        for (let i = 0; i < pageNumbers.length; i = i + 1) {
            let pageNumber = pageNumbers[i];
            if (parseInt(pageNumber.innerHTML) === newPage) {
                pageNumber.classList.add("pagination__block--active");
            }
        };
    }
    else {
        callGoogleAPI(91, true);
        sessionStorage.setItem("searchCurrentPage", 10);
        for (let i = 0; i < pageNumbers.length; i = i + 1) {
            let pageNumber = pageNumbers[i];
            if (parseInt(pageNumber.innerHTML) === 10) {
                pageNumber.classList.add("pagination__block--active");
            }
        };
    }
});

/*Function for sort dropdown*/
$("#resultsSort").change(function () {
    switch (this.value) {
        case "Descending":
            $(".content-card").each(function () {
                $(this).parent().prepend(this);
            });
            break;

        case "Ascending":
            $(".content-card").each(function () {
                $(this).parent().prepend(this);
            });
            break;
    }
});

$("a[data-search-type]").click(function () {
    if ($(this).attr("active") == 1) {
        $(this).attr("active", "0");
    }
    else {
        $(this).attr("active", "1");
    }
    buildSearchTypeFilters(this);
    resetContentCards();
    var currentPage = sessionStorage.getItem("searchCurrentPage");
    if (currentPage > 1) {
        callGoogleAPI((currentPage * 10) + 1, false);
    }
    else {
        callGoogleAPI(1, false);
    }
    return false;
});

//TODO: Implement filter by categories using the appropriate metadata
$("a[data-filter]").click(function () {
    if ($(this).attr("active") == 1) {
        $(this).attr("active", "0");
    }
    else {
        $(this).attr("active", "1");
    }
    buildSearchFilters(this);
    resetContentCards();
    var currentPage = sessionStorage.getItem("searchCurrentPage");
    if (currentPage > 1) {
        callGoogleAPI((currentPage * 10) + 1, false);
    }
    else {
        callGoogleAPI(1, false);
    }
    return false;
});

function buildSearchFilters(item) {
    sessionStorage.setItem("searchFilter", "");
    let filterString = "";
    if ($(item).attr("value") != "" && $(item).attr("active") == 1) {
        filterString = "+more:title:" + $(item).attr("value");
    };

    sessionStorage.setItem("searchFilter", filterString);
};

function buildSearchTypeFilters(item) {
    sessionStorage.setItem("searchType", "");
    let typeString = "";
    if ($(item).attr("value") == "pdf" && $(item).attr("active") == 1) {
        typeString = "&filetype=pdf";
    }
    if ($(item).attr("value") == "images" && $(item).attr("active") == 1) {
        typeString = "&searchType=image";
    }
    sessionStorage.setItem("searchType", typeString);
}

function resetContentCards() {
    $(".content-card").each(function (index) {
        $(this).addClass("s-hide");
    });
}


function resetActivePagination() {
    $(".pagination__body").children("a").each(function (index) {
        if ($(this).hasClass("pagination__block--active")) {
            $(this).removeClass("pagination__block--active");
        }
    });
}

function resetPagination() {
    $(".pagination__body").children("a").each(function (index) {
        if (!$(this).children("i")) {
            if ($(this).hasClass("pagination__block")) {
                $(this).removeClass("pagination__block");
                $(this).removeClass("pagination__block--active");
                $(this).text("");
            }
        }
    });
}

function callGoogleAPI($start, $pagination) {
    var searchData = {
        q: sessionStorage.getItem("searchTerms"),
        num: "10",
        start: $start,
        cx: "012076615123643664511:-ppbzyulzsw",
        key: "AIzaSyCTpy5urevylsMNwCSa1I-DszMf7JG2HOY",
        fields: "queries(request(totalResults)), items"
        //siteSearch: getSiteFilter(window.location.pathname)
    };

    if (sessionStorage.getItem("searchType") != "") {
        if (sessionStorage.getItem("searchType").includes("pdf")) {
            searchData.fileType = "pdf";
        };

        if (sessionStorage.getItem("searchType").includes("image")) {
            searchData.searchType = "image";
        };
    };

    if (sessionStorage.getItem("searchFilter") != "" && sessionStorage.getItem("searchFilter") != null) {
        searchData.q = sessionStorage.getItem("searchTerms") + sessionStorage.getItem("searchFilter");
    };

    $.ajax({
        type: "GET",
        url: "https://www.googleapis.com/customsearch/v1",
        cache: false,
        data: searchData,
        success: function (response) {
            renderGoogleJsonResult(response, $pagination);
        },
        error: function (error) {
            alert("An error occurred with Google API receiving an invalid response!");
            console.log(error);
        }
    });
}

function renderGoogleJsonResult($json, $pagination) {
    let totalResults = $json.queries.request[0].totalResults;
    var resultsAmount;
    if (totalResults >= 100) {
        $("#results-count").text(100);
        resultsAmount = 100;
    }
    else {
        $("#results-count").text(totalResults);
        resultsAmount = totalResults;
    };

    if (!$pagination) {
        $("#card-container > p:contains(\"No Results Found\")").remove();
        resetPagination();
        populatePagination(sessionStorage.getItem("searchCurrentPage"), Math.ceil(resultsAmount / 10));
        sessionStorage.setItem("searchTotalPages", Math.ceil(resultsAmount / 10));
    }

    $("#search-terms").text("'" + sessionStorage.getItem("searchTerms") + "'");
    $.each($json.items, function (key, value) {
        $(".content-card").each(function (index) {
            if ($(this).hasClass("s-hide")) {
                $(this).removeClass("s-hide");
                $(this).find(".content-card__title").html(value.htmlTitle);
                $(this).find(".content-card__copy").html(value.htmlSnippet);
                $(this).attr("href", value.link);
                return false;
            }
        });
    });

    if (parseInt(resultsAmount) === 0) {
        $("#card-container").append("<p>No Results Found</p>");
    }
}

function generatePagination($start, $end) {
    $("a[data-page]").remove();

    for (var i = 0; i <= Math.ceil($end); i = i + 1) {
        $("#pagePrev").after("<a data-page href=\"\"></a>");
    };

    $("a[data-page]").click(function (e) {
        sessionStorage.setItem("searchCurrentPage", $(this).text());
        resetContentCards();
        resetActivePagination();
        $(this).addClass("pagination__block--active");
        var currentPage = sessionStorage.getItem("searchCurrentPage");
        var nextPage = ((currentPage - 1) * 10) + 1;
        if (currentPage > 1) {
            callGoogleAPI(nextPage, true);
        }
        else {

            callGoogleAPI(1, true);
        }
        return false;
    });
}

function populatePagination($start, $end) {
    let count = $start;
    generatePagination($start, $end);
    $(".pagination__body").children("a").each(function (index) {
        if (!$(this).hasClass("pagination__block")) {
            if (count <= $end || $start == $end) {
                $(this).addClass("pagination__block");
                $(this).addClass("pagination__page");
                if (count == sessionStorage.getItem("searchCurrentPage")) {
                    $(this).addClass("pagination__block--active");
                }
                $(this).text(count);
                if ($start == $end) {
                    return false;
                }
                count++;
            }
        }
    });
}

function renderTypeCount($type, $json) {
    $("a[data-search-type]").each(function (index) {
        if ($(this).attr("value") == $type) {
            $(this).find("span").text($json.queries.request[0].totalResults);
        }
    });
}

function retrieveTypeResultCount($type) {
    $.ajax({
        type: "GET",
        url: "https://www.googleapis.com/customsearch/v1",
        cache: false,
        data:
        {
            q: sessionStorage.getItem("searchTerms") + " filetype:" + $type,
            cx: "012076615123643664511:-ppbzyulzsw",
            key: "AIzaSyCTpy5urevylsMNwCSa1I-DszMf7JG2HOY",
            fields: "queries(request(totalResults))"
            //siteSearch: getSiteFilter(window.location.pathname)
        },
        success: function (response) {
            renderTypeCount($type, response);
        },
        error: function (error) {
            alert("An error occurred with Google API receiving an invalid response!");
            console.log(error);
        }
    });
}

//TODO: Define final site filters from Custom Search settings
function getSiteFilter(input) {
    var testUrl = String(input);
    var siteFilter = "www.dignityfunerals.co.uk";

    if (testUrl.startsWith("corporate")) {
        siteFilter += "/corporate";
        return siteFilter;
    };

    if (testUrl.startsWith("crematoria")) {
        siteFilter += "/crematoria";
        return siteFilter;
    };

    return siteFilter;
};