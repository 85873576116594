import { breakpoints } from '../utils/breakpoints'
import $ from "jquery";

const stickyCtas = document.querySelectorAll('.sticky-cta')

stickyCtas.forEach(sticky => {
	const items = sticky.querySelectorAll('.sticky-cta__item')

	items.forEach(item => {
		item.addEventListener('click', e => {
			if(window.innerWidth < breakpoints.medium) {
				if(!sticky.classList.contains('sticky-cta--open')) {
					e.preventDefault()

					sticky.classList.add('sticky-cta--open')
				}
			}
		})
	})
})

$(document).ready(function () {
    /* Sticky Find an FD CTA */
    $('.find-fd h3').on('click', function() {
        let iconAtt = $(this).parent().find('i');
        if (!$(this).parents('.find-fd').hasClass('active')) {
        
            if (iconAtt.hasClass('icon-up-arrow')) {
                iconAtt.removeClass('icon-up-arrow');
                iconAtt.addClass('icon-cross');
                $(this).parents('.find-fd').addClass('active');
            }
        }
    });

    $('.find-fd__head').on('click', '.icon-cross', function() {
        let el = $(this);
        if (el.parent().parent('.find-fd').hasClass('active')) {
            el.parent().parent('.find-fd').removeClass('active');
            el.removeClass('icon-cross');
            el.addClass('icon-up-arrow');
        }
    });
});