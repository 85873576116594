import $ from "jquery";

$(document).ready(function () {
    $(".alpha-search__section").each(function () {
        var alphaSelect = $(this).find("select");
        alphaSelect.on("change", function (e) {
            var alphaLink = "#section-" + $(this).val().toLowerCase() + "-trigger";
            if (!($(alphaLink).hasClass('alpha-nav--disabled'))) {
                $(alphaLink)[0].click();
            }
            alphaSelect[0].selectedIndex = 0;
        })
    })
})