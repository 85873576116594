import { breakpoints } from '../utils/breakpoints'
const searchInput = document.querySelector('.inline-form__input--location');

placeholderText();

window.addEventListener('resize', (e) => {
    placeholderText();
})

function placeholderText() {
    if (searchInput) {
        if (window.innerWidth > breakpoints.small) {
            searchInput.placeholder = "Enter postcode, town or location.";
        } else {
            searchInput.placeholder = "Enter location.";
        }
    }
}