import $ from "jquery";


export function loadScreen(element, loadclass) {
    $(element).addClass("loader");
    loadclass !== undefined ? $(element).addClass(loadclass) : null;
};

export function removeLoadScreen(element, loadclass) {

    if ($(element).hasClass("loader")) {
        $(element).removeClass("loader");
    };

    if ($(element).hasClass(loadclass)) {
        $(element).removeClass(loadclass);
    };
};

