import $ from "jquery";
const ctRoots = $(".comparison-tb");
const sourceTable = ctRoots.find(".comparison-table:not(.comparison-table--mob)");
const trShell = "<tr><td></td></tr>";

// Populate the mobile comparision table HTML structure (stacked x 4) with content from the Desktop markup
ctRoots.each(function (i, ctRoot) {

    $(ctRoot).find(".comparison-table--mob").each(function (index) {
        

        // Header, and first body table cell containing text
        var self = this;

        $(self).find("th")
            .html(sourceTable.children("thead")
                .find("th").eq(index + 1)
                .find(".comparison-card")
                .clone());

        $(self).find("tbody")
            .find("td")
            .append(sourceTable.children("tbody")
                .children("tr").eq(0)
                .children("td:first")
                .children()
                .clone())
            .append(sourceTable.children("tbody")
                .children("tr").eq(0)
                .children("td").eq(index + 1)
                .children()
                .clone());

        // Repeating elements, containing tick / cross icons
        sourceTable.children("tbody").children(".comparison-table__benefit").each(function () {

            $(trShell).insertAfter($(self).find("tbody").children("tr:last"));

            $(this).children("td:first")
                .children()
                .clone()
                .appendTo($(self).find("tbody")
                    .children("tr:last")
                    .children("td:first"));

            $(this).children("td").eq(index + 1)
                .children()
                .clone()
                .appendTo($(self).find("tbody")
                    .children("tr:last")
                    .children("td:first"));
        })

        // Price info and CTA button
        sourceTable.children("tbody").children(".comparison-table__priceinfo").each(function () {

            $(trShell).insertAfter($(self).find("tbody").children("tr:last"));

            $(this).children("td").eq(index)
                .children()
                .clone()
                .appendTo($(self).find("tbody")
                    .children("tr:last")
                    .children("td:first"));
        })
    })
})

