import $ from 'jquery'
import lity from 'lity'

const modals = document.querySelectorAll('.modal')
const printModalTrigger = document.querySelectorAll('.js-print-modal')
const addEmailTrigger = document.querySelectorAll('.js-add-email-modal')
const removeEmailTrigger = document.querySelectorAll('.js-remove-email-modal')
const emailContainer = document.querySelectorAll("#email_share_container")

if (emailContainer) {
    const clone = document.getElementById("email_share");
  //  var clone = document.querySelectorAll(target)[0];
    emailContainer.forEach(container => {
        container.insertAdjacentHTML('beforeend', clone.innerHTML);
    });
}


$(document).on('click', '[data-lity-open]', (e) => {
    e.preventDefault;
    document.querySelector('body').classList.add('body--overflow-hidden')
    document.querySelector('html').classList.add('html--overflow-hidden')
})

$(document).on('click', '[data-lity-close]', (e) => {
    e.preventDefault;
    document.querySelector('body').classList.remove('body--overflow-hidden')
    document.querySelector('html').classList.remove('html--overflow-hidden')
})

$(document).on('lity:resize', (event, instance) => {   
    var modalContent = instance.element().find('.modal__content')[0]

    if (modalContent) {
        modalContent.style.maxHeight = `${window.innerHeight - 32}px`
    }   
});

modals.forEach(modal => {
    var modalClose = modal.querySelector('.modal__close')
    var modalContent = modal.querySelector('.modal__content')

    if (modalClose) {
        modalClose.addEventListener('click', () => { lity.current().close() })
    }

    if (modalContent) {
        modalContent.style.maxHeight = `${window.innerHeight - 32}px`
    }
})

printModalTrigger.forEach(trigger => {
    trigger.addEventListener('click', e => {
        e.preventDefault()

        document.body.classList.add('print-modal')
        window.print()
    })
})

addEmailTrigger.forEach(trigger => {
    trigger.addEventListener('click', e => {

        var clone = document.querySelectorAll(e.currentTarget.getAttribute("data-target"))[0];
        var target = document.querySelectorAll(clone.getAttribute("data-target"))[0];

        var count = target.children.length;

        // var parser = new DOMParser()
        // var node = parser.parseFromString(clone.childNodes[0],'text/html')

        var myContainer = document.getElementById("email_share_container")
        var myEmailblock = document.getElementsByClassName("js-test")
        var textNode = document.createTextNode(clone.innerHTML)

        if (count < 100) {


            //target.innerHTML += clone.innerHTML	
            //myContainer.appendChild(myEmailblock)	
            myContainer.insertAdjacentHTML('beforeend', clone.innerHTML);

        }

        var trigger_remove = document.getElementsByClassName("js-remove-email-modal")


        for (var i = 0; i < trigger_remove.length; i++) {

            trigger_remove[i].addEventListener('click', removeNode)

        }

    })
})
function removeNode(e) {
    console.log("check remove trigger")

    e.currentTarget.parentNode.remove();

}

removeEmailTrigger.forEach(trigger => {
    trigger.addEventListener('click', removeNode)

})