const top = document.querySelector('.top')

if (top) {
    top.addEventListener('click', (e) => {
        e.preventDefault()

        scroll(0, 0)
    })
    var toggleTopControl = function () {
        var scrollTop = Math.round(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        top.style.display = "flex";
        if (window.innerWidth <= 768 && scrollTop <= Math.round(window.innerHeight / 2)) {
            top.style.display = "none";
        }
    };
    window.addEventListener('load', toggleTopControl);
    window.addEventListener('scroll', toggleTopControl);
    window.addEventListener('resize', toggleTopControl);
}
