const sizesTriggers = document.querySelectorAll('[data-size]')
const htmlElement = document.querySelector('html')
const sizesActiveClass = 'header__size--active'

if(sizesTriggers) {
	sizesTriggers.forEach(item => {
		const size = item.dataset.size
	
		item.addEventListener('click', e => {
			e.preventDefault()
	
			htmlElement.style.fontSize = `${size}px`
	
			// Remove active class from other elements
			sizesTriggers.forEach(item => {
				if(item.classList.contains(sizesActiveClass)) {
					item.classList.remove(sizesActiveClass)
				}
			})
	
			// Add active class to this element
            item.classList.add(sizesActiveClass)

            var evt = document.createEvent("Event");
            evt.initEvent("resize", true, true);
            window.addEventListener("resize", function (e) { }, false);
            window.dispatchEvent(evt);
		})
	})
}
