import $ from "jquery";
const tabs = document.querySelectorAll(".tabs");
const secAccOuter = '<div class="accordionsec accordion--single-view"><div class="accordion__items"></div></div>';
const secAccInner = '<div class="accordion__item accordion__item--sec"><h3 class="accordion__title"> <i class="icon-down-chevron"></i></h3 ><div class="accordion__content"><div class="accordion__copy"></div></div></div >';

var tabtitle = [];
var tabcont = [];
var tabtitlesec = [];
var tabcontsec = [];

// [].slice.call converts a NodeList to an array
if(tabs) {
	tabs.forEach(function(tab, i) {
		var tabTriggersParent = tab.querySelector(".tabs__triggers");
		var tabTriggers = [].slice.call(tabTriggersParent.querySelectorAll(".tabs__trigger"));

		var tabContentsParent = tab.querySelector(".tabs__content");
		var tabContents = [].slice.call(tabContentsParent.querySelectorAll(".tab"));

		// Filter out tab contents so we only get direct children
		tabContents = tabContents.filter((tabContent, i) => { return tabContent.parentNode == tabContentsParent });

		// Set the first in any tabset to be active
		tabTriggers.forEach((tabTrigger) => { tabTrigger.classList.remove("tabs__trigger--active"); });
		tabContents.forEach((tabContent) => { tabContent.classList.remove("tab--active"); });
		tabTriggers[0].classList.add("tabs__trigger--active");
		tabContents[0].classList.add("tab--active");

		// On click of any tab trigger...
		tabTriggers.forEach((tabTrigger, i) => {
			tabTrigger.addEventListener("click", () => {

				// Remove classes from other tab triggers and contents so they're not active
				tabTriggers.forEach((tabTrigger) => { tabTrigger.classList.remove("tabs__trigger--active"); });
				tabContents.forEach((tabContent) => { tabContent.classList.remove("tab--active"); });

				// Make the trigger we clicked, and its corresponding contents active
				tabTrigger.classList.add("tabs__trigger--active");
                tabContents[i].classList.add("tab--active");
                var evt = document.createEvent("Event");
                evt.initEvent("resize", true, true);
                window.addEventListener("resize", function (e) { }, false);
                window.dispatchEvent(evt);
			})
		});

		// Tab mobile dropdown
		var tabsMobileDropdownButton = tabTriggersParent.querySelector(".tabs__mobile-dropdown-button");
		var tabsMobileDropdown = tabTriggersParent.querySelector(".tabs__mobile-dropdown");
		var tabsMobileDropdownItems = tabTriggersParent.querySelectorAll(".tabs__mobile-dropdown-item");

		if(tabsMobileDropdownButton && tabsMobileDropdown) {
			tabsMobileDropdownButton.addEventListener("click", (e) => {
				tabsMobileDropdown.classList.toggle("tabs__mobile-dropdown--show");
                tabsMobileDropdownButton.classList.toggle("tabs__mobile-dropdown-button--close");
                window.dispatchEvent(new Event('resize'));
			});
		}

		if(tabsMobileDropdownItems) {
			tabsMobileDropdownItems.forEach((item, i) => {
				item.addEventListener("click", (e) => {
					// Remove classes from other tab triggers and contents so they're not active
					tabTriggers.forEach((tabTrigger) => { tabTrigger.classList.remove("tabs__trigger--active"); });
					tabContents.forEach((tabContent) => { tabContent.classList.remove("tab--active"); });

					// Make the trigger we clicked, and its corresponding contents active
					tabTriggers[i].classList.add("tabs__trigger--active");
                    tabContents[i].classList.add("tab--active");
                    var evt = document.createEvent("Event");
                    evt.initEvent("resize", true, true);
                    window.addEventListener("resize", function (e) { }, false);
                    window.dispatchEvent(evt);

					tabsMobileDropdown.classList.remove("tabs__mobile-dropdown--show");
					tabsMobileDropdownButton.classList.remove("tabs__mobile-dropdown-button--close");
				})
			});
        }

        /*
            Replicate inner markup from tab content into accordion items
        */
        $(tab).children('.tabs__triggers')
              .children('.tabs__trigger')
              .each(function () {

            tabtitle.push($(this).html());
        })

        $(tab).children('.accordion')
              .children('.accordion__items')
              .children('.accordion__item')
              .each(function () {

            $(this).children('.accordion__title').prepend(tabtitle.shift());
        })

        $(tab).children('.tabs__content')
              .children('.tab')
              .children('.tab__content')
              .each(function (index) {

            var secAcc = $(this).children('.tabs');
            var secAccItems = $(this).children('.tabs').find('.tab').length;

            if (secAcc.length > 0) {
                renderSecondaryAccordion(secAcc, secAccItems, $(tab).children('.accordion').children('.accordion__items').children('.accordion__item').children('.accordion__content').eq(index));
                tabcont.push('');
            } else {
                tabcont.push($(this).html());
            }
        })

        $(tab).children('.accordion')
              .children('.accordion__items')
              .children('.accordion__item')
              .children('.accordion__content')
              .children('.accordion__copy')
                .each(function () {

            $(this).prepend(tabcont.shift());
        })
    });
}

function renderSecondaryAccordion(jQObj, numItems, rootAccTab) {
    const secAccContainer = rootAccTab.children('.accordion__copy');
    var secAccRoot = jQObj;

    // Render new second-level accordion structure with appropriate number of 'items'
    secAccContainer.addClass('accordion__secondary').prepend(secAccOuter);
    for (var itms = 0; itms < numItems; itms++) {
        secAccContainer.children('.accordionsec')
                       .children('.accordion__items')
                       .prepend(secAccInner);
    }

    secAccRoot.children('.tabs__triggers').children('.tabs__trigger').each(function () {
        tabtitlesec.push($(this).html());
    })

    rootAccTab.find('.accordionsec')
              .children('.accordion__items')
              .children('.accordion__item')
              .each(function () {

        $(this).children('.accordion__title').prepend(tabtitlesec.shift());
    })

    secAccRoot.children('.tabs__content').children('.tab').each(function () {
        tabcontsec.push($(this).html());
    })

    rootAccTab.find('.accordionsec')
              .children('.accordion__items')
              .children('.accordion__item')
              .children('.accordion__content')
              .children('.accordion__copy')
              .each(function () {

        $(this).html(tabcontsec.shift());
    })
}
