import '../polyfills/document-currentscript';
var currentScript = document.currentScript;

/**
 * Function to derive api url
 * @param {any} appEnv - The current app envrionment
 * @returns {string} - Api url for current environment
*/
export function getApiUrl(appEnv) {
    var assetsUrl = "";

    switch (appEnv) {
        case "live":
            assetsUrl = "https://www.dignityfunerals.co.uk";
            break;

        case "stg":
            assetsUrl = "https://dignityfunerals-stg.dignitytest.co.uk";
            break;

        case "uat":
            assetsUrl = "https://dignityfunerals-uat.dignitytest.co.uk";
            break;

        case "dev":
            assetsUrl = "https://dignityfunerals-dev.dignitytest.co.uk";
            break;

        case "local":
            assetsUrl = "https://dignity20cms-local.dignitytest.co.uk";
            break;

        default:
            assetsUrl = "https://dignityfunerals-uat.dignitytest.co.uk";
            break;
    }

    return assetsUrl;
};

/**
 * Function to derive assets url
 * @returns {string} - Assets url for current environment
 */
export function getAssetsUrl() {
    var srcUrl = /^(?:\w+\:\/\/)?([^\/]+)\/(assets([0-9a-zA-Z]*))(.*)$/.exec(currentScript.src);

    var domainName = srcUrl[1];
    var assets = srcUrl[2];

    return window.location.protocol + "//" + domainName + "/" + assets;
};