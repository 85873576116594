import $ from "jquery";
import 'awesomplete';
import * as helpers from "../apis/helpers";
import { loadScreen, removeLoadScreen } from "../utils/loader";

const mapElementSelector = '.map__object';
const testClass = "submit";
const mapElements = document.querySelectorAll(mapElementSelector);
const autoCompletes = document.querySelectorAll('.autocomplete');
const searchBlocks = document.querySelectorAll('.branch-cta');
var env;

/*
 * Initalises map, predictive search and search using CTA value (if available) on load of page
 */
$(document).ready(function () {
    //initialiseAutocomplete(); - Reactivate to enable predictive search + nearest location
    initialiseCtaSearch();
    initialiseMap();
    const querySearch = getParameterByName("q");
    if (querySearch != null) {
        getResults(querySearch, "search");
        $("#mapInput").val(querySearch);
    }
});

/**
 * Function to get nearest locations from the API
 * @param {any} userAddress - The origin address provided by user/device
 * @param {any} userAddressType - The type of origin provided (search/currentloc)
 * @param {string} env - The current app environment
 * @returns {string} - JSON response from the API
 */
function getNearestLocations(userAddress, userAddressType, env) {
    if (userAddress != "") {
        const trimmedAddress = userAddress.trim();

        if (trimmedAddress != "" && userAddressType != "") {
            var requestUrl = helpers.getApiUrl(env) + "/api/nearestfds/" + trimmedAddress + "/" + userAddressType + "/";

            return $.ajax({
                type: "GET",
                url: requestUrl,
                dataType: "json"
            });
        };
    };
};


/**
 * Function to get search predictions from Google Maps Places Autocomplete API via webservice
 * @param {any} userInput - Value to be looked up from Google
 * @param {string} env - The current app environment
 * @param {any} userSession - The session token
 */
//Session token used to help reduce amount of charged calls per user: https://developers.google.com/places/web-service/autocomplete#session_tokens
function getPredictions(userInput, userSession, env) {
    if (userInput != "" && userSession != "") {
        var requestUrl = helpers.getApiUrl(env) + "/api/locationpredictions/" + userInput + "/" + userSession + "/";

        return $.ajax({
            type: "GET",
            url: requestUrl,
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        });
    };
};

/**
 * Function to get more data for the speified locations
 * @param {any} locationIds - String of comma seperated location IDs
 * @param {string} env - The current app environment
 * @returns {any} - Array of locations containing more details for each specified location
 */
function getLocationData(locationIds, env) {
    if (locationIds != "") {
        var requestUrl = helpers.getApiUrl(env) + "/api/locationsdetails/";
        var requestParam = JSON.stringify({ LocationIds: { BranchIds: locationIds } });

        return $.ajax({
            type: "POST",
            url: requestUrl,
            contentType: "application/json; charset=utf-8",
            data: requestParam,
            dataType: "json"
        });
    };
};

/**
 * Function to get/set search values for CTAs
 * */
function initialiseCtaSearch() {
    //Reads CTA search value if available and performs search
    const ctaSearch = sessionStorage.getItem("BranchCTA");

    if (ctaSearch !== "" && ctaSearch !== null) {
        if (ctaSearch.includes(",-")) { //Check to see if value is a geocode
            getResults(ctaSearch, "currentloc");
        }
        else {
            getResults(ctaSearch, "search");
            $("#mapInput").val(ctaSearch);
        };
        sessionStorage.removeItem("BranchCTA");
    };

    //Stores Nav Branch Search CTA into session for access by the Find FD page
    if ($("#branch-nav-cta").length) {
        $("#branch-nav-cta").submit(function () {
            sessionStorage.setItem("BranchCTA", $("#branch-nav-cta-input").val());
        });
    };

    //Stores Nav Branch Search CTA into session for access by the Find FD page
    if ($("#branch-banner-cta").length) {
        $("#branch-banner-cta").submit(function () {
            sessionStorage.setItem("BranchCTA", $("#branch-banner-cta-input").val());
        });
    };

    if (searchBlocks.length) {
        searchBlocks.forEach(form => {
            form.addEventListener('submit', function (e) {
                sessionStorage.setItem("BranchCTA", form.getElementsByTagName('input')[0].value);
            });
        });
    };

    /* 
    * Event listener to get user's current location when '.branch-finder-load-current-location' is clicked 
    */
    document.querySelectorAll("a.branch-finder-load-current-location, .branch-finder-load-current-location a").forEach(element => {
        element.addEventListener('click', e => {
            e.preventDefault();
            loadUserCurrentLocation(function (position) {
                let form = element.closest("form");
                sessionStorage.setItem("BranchCTA", position);
                getResults(position, "currentloc");
                if (!location.href.includes("#map")) {
                    form.querySelector(".inline-form__input[type='search']").value = position;
                    form.querySelector("button").click();
                }
            });
        });
    });

    document.querySelectorAll("a.flip-map-load-current-location, .flip-map-load-current-location a").forEach(element => {
        element.addEventListener('click', e => {
            e.preventDefault();
            loadUserCurrentLocation(function (position) {
                sessionStorage.setItem("BranchCTA", position);
                getResults(position, "currentloc");
                if (searchBlocks.length) {
                    searchBlocks.forEach(form => {
                        if (!location.href.includes("#map")) {
                            form.querySelector(".inline-form__input[type='search']").value = position;
                            form.querySelector("button").click();
                        }
                    });
                };

            });
        });
    });
};


/** 
 * Function to get users location and return to callback function 
 * @param {any} then - Callback to apply on complete 
 * @param {any} failed - Callback to apply on failure, if none supplied alert will be displayed 
 */
function loadUserCurrentLocation(then, failed) {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            let currentLocation = position.coords.latitude + "," + position.coords.longitude;
            if (typeof then === "function") {
                then.apply(null, [currentLocation]);
            }
        }, function (err) {
            let message = "Could not find your location at this time";
            switch (err.code) {
                case err.PERMISSION_DENIED:
                    message = "Please enable loction services to use this feature";
                    break;
                case err.POSITION_UNAVAILABLE:
                case err.TIMEOUT:
                default:
                    message = "Could not detect your location at this time";
                    break;
            }
            if (typeof failed === "function") {
                failed.apply(null, [err]);
            } else {
                alert(message);
            }
        });
    } else {
        alert("This device does not support location services");
    }
};

/**
 * Function to intitialise the map on load of the page
 * */
function initialiseMap() {
    mapElements.forEach(mapElement => {
        if (mapElement.dataset.active) {
            const map = new google.maps.Map(mapElement, {
                center: {
                    lat: parseFloat(mapElement.dataset.lat),
                    lng: parseFloat(mapElement.dataset.lng)
                },
                zoom: parseFloat(mapElement.dataset.zoom) ? parseFloat(mapElement.dataset.zoom) : 8
            });
            env = mapElement.dataset.env;

            if (mapElement.dataset.type == "branch") {
                const icon = helpers.getAssetsUrl() + '/img/maps/marker-default.svg';
                const markerIcon = {
                    url: icon,
                    origin: new google.maps.Point(0, 0),
                    labelOrigin: new google.maps.Point(20, 25),
                    scaledSize: new google.maps.Size(60, 87)
                };
                var marker = new google.maps.Marker({
                    position: {
                        lat: parseFloat(mapElement.dataset.lat),
                        lng: parseFloat(mapElement.dataset.lng)
                    },
                    icon: markerIcon
                });

                marker.setMap(map);
            };

            if (mapElement.dataset.type == "branches") {
                const icon = helpers.getAssetsUrl() + '/img/maps/marker.svg';
                const branchMarkers = JSON.parse(mapElement.dataset.markers);
                const markerInfowindow = new google.maps.InfoWindow;
                const markerStyle = mapElement.dataset.markerStyle;

                branchMarkers.forEach((x, i) => {
                    let marker = "";
                    let markerContent = "";

                    const markerIcon = {
                        url: icon,
                        origin: new google.maps.Point(0, 0),
                        labelOrigin: new google.maps.Point(20, 25),
                        scaledSize: new google.maps.Size(60, 87)
                    };

                    var markerLabel = {
                        text: `${i + 1}`,
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 'bold'
                    };

                    if (markerStyle !== 'numbered') {
                        markerLabel = null;
                    }

                    marker = new google.maps.Marker({
                        position: {
                            lat: parseFloat(x.Yext_BranchLatitude),
                            lng: parseFloat(x.Yext_BranchLongitude)
                        },
                        icon: markerIcon,
                        label: markerLabel
                    });

                    markerContent = generateBranchesMapCard(x, env);

                    google.maps.event.addListener(map, 'click', function () {
                        markerInfowindow.close(map, marker);
                    });

                    marker.addListener('click', function () {
                        markerInfowindow.setContent(markerContent);
                        markerInfowindow.open(map, marker);
                    });

                    marker.setMap(map);
                });
            };
        }
    });
};

/**
 * Function to initlise the predictive search functionality on the search box
 * */
function initialiseAutocomplete() {
    autoCompletes.forEach(input => {
        const locationText = input.dataset.locationText ? input.dataset.locationText : 'Use current location';
        const li = document.createElement('li');
        li.classList.add('form__input--location');
        li.innerHTML = `<a href="">${locationText}</a>`;

        var comboplete = new Awesomplete(input, { list: [], minChars: 0 });
        comboplete.ul.classList.add('awesomplete__list--geolocation-enabled');
        comboplete.ul.appendChild(li);

        /*
         * Event listener to open prediction list on focus of search box
         */
        input.addEventListener('focus', () => {
            if (comboplete.ul.childNodes.length === 0) {
                comboplete.minChars = 0;
                comboplete.evaluate();
                comboplete.ul.appendChild(li);
            } else if (comboplete.ul.hasAttribute('hidden')) {
                comboplete.ul.appendChild(li);
                comboplete.open();
            } else {
                comboplete.close();
            }
        });

        /*
         * Event listener to provide search predictions as the user types
         */
        input.addEventListener("input", () => {
            if (input.value.length == 0) {
                comboplete.ul.innerHTML = "";
            }
            else {
                var newList = [];
                getPredictions(input.value, getSessionToken(), env).done(function (result) {
                    if (result.status == "OK") {
                        for (var x = 0; x < result.predictions.length; x = x + 1) {
                            newList.push(result.predictions[x].description);
                        };
                    };
                });
                comboplete.list = newList;
                comboplete.evaluate();
                comboplete.open();
                comboplete.ul.appendChild(li);
            };
        });

        /*
         * Event listener to close predictions and perform search once a prediction is selected
         */
        input.addEventListener("awesomplete-selectcomplete", e => {
            e.preventDefault();
            getResults(input.value, "search");
        });

        /*
         * Event listener to perform search based on user's current location
         */
        li.addEventListener('click', e => {
            e.preventDefault();
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    const currentLocation = position.coords.latitude + "," + position.coords.longitude;
                    getResults(currentLocation, "currentloc");
                }, function () {
                    alert("Please enable Geolocation on your browser!");
                });
            }
        });
    });
};

/**
 * Function to validate queries in map search form
 * @param {any} query - Value from search input
 * @param {any} queryType - Type of search to perform
 */
function executeMapSearch(query, queryType) {
    if (query.length > 0) {
        getResults(query, queryType);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'event': 'find-a-fd.submit', 'gtm.element': document.getElementById("branch-search-form"), 'gtm.elementId': 'branch-search-form' });
    }
    else {
        location.href = "#map";
        $(".branch__cards").empty();
        $(".map__results").empty();
        $(".map__results").append(`0 Results`);
    };
};

/*
 * Event listener to perform search when search button is clicked
 */
$(".mapSearch").on('click', function (e) {
    e.preventDefault();
    var search = $("#mapInput").val();
    executeMapSearch(search, "search");
});

/*
 * Event listener to perfrom serarch when enter key is pressed
 */
$("#mapInput").keypress(function (e) {
    var key = e.charCode || e.keyCode || 0;
    if (key == 13) {
        e.preventDefault();
        var search = $("#mapInput").val();
        executeMapSearch(search, "search");
    };
});


/**
 * Function to render Get Nearest results to screen
 * @param {any} address - The input origin address to calculate distances from
 * @param {any} addressType - The type of query (search/currentloc)
 */
function getResults(address, addressType) {
    //initialiseMap();
    mapElements.forEach(mapElement => {
        //Show loading screen
        location.href = "#map"
        const mapZoom = parseFloat(mapElement.dataset.zoom) ? parseFloat(mapElement.dataset.zoom) : null;
        const mapType = mapElement.dataset.type;
        const markerStyle = mapElement.dataset.markerStyle;
        env = mapElement.dataset.env;
        const iconBase = helpers.getAssetsUrl() + '/img/maps/';
        const icons = {
            default: {
                icon: iconBase + 'marker.svg'
            },
            active: {
                icon: iconBase + 'marker-default.svg'
            },
            numbered: {
                icon: iconBase + 'marker-plain.svg'
            }
        };
        let markers = [];

        let requestAddress = "";
        if (address.search(", UK") !== -1) {
            requestAddress = address.substring(0, address.length - 4);
        }
        else {
            requestAddress = address;
        };

        var locationIds = [];

        loadScreen(mapElement.parentElement.parentElement);

        //Generates markers if locations are available
        getNearestLocations(requestAddress, addressType, env).done(function (result) {
            //Removing loading screen
            removeLoadScreen(mapElement.parentElement.parentElement);

            var mapStatic = document.getElementsByClassName("map__static")[0];
            if (mapStatic != null) {
                mapStatic.parentElement.removeChild(mapStatic);
                mapElement.style.cssText = "height: 600px"
            }

            const map = new google.maps.Map(mapElement, {
                center: {
                    lat: result.UserGeocodes.Latitude,
                    lng: result.UserGeocodes.Longitude
                },
                zoom: mapZoom ? mapZoom : 8
            });

            var listCards = [];

            setLocationsCache(result.LocationCompare);

            const markersData = result.LocationCompare;

            if (markersData) {
                const markerInfowindow = new google.maps.InfoWindow;

                markersData.forEach((m, i) => {
                    if (i < 6) {
                        const markerIcon = {
                            url: markerStyle == 'numbered' ? icons['numbered'].icon : icons['default'].icon,
                            origin: new google.maps.Point(0, 0),
                            labelOrigin: new google.maps.Point(20, 25),
                            scaledSize: new google.maps.Size(60, 87)
                        };

                        const markerLabel = {
                            text: `${i + 1}`,
                            color: '#fff',
                            fontSize: '16px',
                            fontWeight: 'bold'
                        };

                        //var markerContent = "";
                        var marker;

                        switch (mapType) {
                            case "fs":
                                marker = new google.maps.Marker({
                                    position: {
                                        lat: m.Data.BranchLatitude,
                                        lng: m.Data.BranchLongitude
                                    },
                                    title: m.Data.BranchDisplayName ? m.Data.BranchDisplayName : '',
                                    icon: markerIcon,
                                    label: markerStyle == 'numbered' ? markerLabel : null,
                                    optimized: false
                                });

                                marker.addListener("click", function () {
                                    var branchCard = $('.branch-card[data-locid="' + m.Data.BranchID + '"]');
                                    initialiseBranchCard(branchCard);
                                });
                                //markerContent = generateFsMapCard(m, mapElement.dataset.env);
                                $(document).ready(function () {
                                    var branchCard = $('.branch-card[data-locid="' + m.Data.BranchID + '"]');
                                    if (i == 0) {
                                        initialiseBranchCard(branchCard);
                                        marker.setIcon({
                                            url: icons.active.icon,
                                            scaledSize: new google.maps.Size(60, 87)
                                        });
                                    };
                                    branchCard.mouseenter(function () {
                                        marker.setIcon({
                                            url: icons.active.icon,
                                            scaledSize: new google.maps.Size(60, 87)
                                        });
                                    });
                                    branchCard.mouseleave(function () {
                                        marker.setIcon({
                                            url: icons.default.icon,
                                            scaledSize: new google.maps.Size(60, 87)
                                        });
                                    });
                                    $('.branch-card').hover(function () {
                                        if (branchCard.parent().hasClass("branch-container--active")) {
                                            marker.setIcon({
                                                url: icons.default.icon,
                                                scaledSize: new google.maps.Size(60, 87)
                                            });
                                            branchCard.blur();
                                        };
                                    });
                                });

                                listCards.push(generateLocationList(m, "fs", mapElement.dataset.env));
                                locationIds.push(m.Data.BranchID);
                                break;

                            case "cremqs":
                                marker = new google.maps.Marker({
                                    position: {
                                        lat: m.Data.Crematorium.CremLatitude,
                                        lng: m.Data.Crematorium.CremLongitude
                                    },
                                    title: m.Data.Crematorium.CremName ? m.Data.Crematorium.CremName : '',
                                    icon: markerIcon,
                                    label: markerStyle == 'numbered' ? markerLabel : null,
                                    optimized: false
                                });
                                markerContent = generateCremQsMapCard(m);
                                listCards.push(generateLocationList(m, "cremqs"));
                                break;
                        }

                        markers.push(marker);

                        google.maps.event.addListener(map, 'click', function () {
                            //markerInfowindow.close(map, marker);

                            if (markerStyle != 'numbered') {
                                markers.map(m => {
                                    m.setIcon({
                                        url: icons.default.icon,
                                        scaledSize: new google.maps.Size(60, 87)
                                    });
                                });
                            }
                        });

                        marker.addListener('click', function () {
                            //markerInfowindow.setContent(markerContent);
                            //markerInfowindow.open(map, marker);

                            if (markerStyle != 'numbered') {
                                markers.map(m => {
                                    m.setIcon({
                                        url: icons.default.icon,
                                        scaledSize: new google.maps.Size(60, 87)
                                    });
                                });
                                marker.setIcon({
                                    url: icons.active.icon,
                                    scaledSize: new google.maps.Size(60, 87)
                                });
                            }
                        });

                        marker.setMap(map);
                    };
                });

                $(".branch__cards").empty();
                $(".map__results").empty();

                if (listCards.length > 0) {
                    for (var i = 0; i < listCards.length; i = i + 1) {
                        $(".branch__cards").append(listCards[i]);
                    };

                    //if (listCards.length < 50) {
                    //    $(".map__results").append(`<li class="u-align-center"><a class="button" id="mapLoadMore" href="#">Load more results</a></li>`);
                    //    $("#mapLoadMore").click(function (e) {
                    //        e.preventDefault();
                    //        loadMoreCards(mapType, env);
                    //    });
                    //};
                    //$(".map__results").append(`${markersData.length} Results`);
                    $(".map__results").append(`6 Results`);
                };
            };
        })
            .fail(function () {
                removeLoadScreen(mapElement.parentElement.parentElement);
                $(".branch__cards").empty();
                $(".map__results").empty();
                $(".map__results").append(`0 Results`);
            });
    });
};

/**
 * Sets a branch card active and focuses onto it
 * @param {any} branchCard - The selected branch card
 */
function initialiseBranchCard(branchCard) {
    branchCard.parent().addClass("branch-container--active");
    branchCard.focus();
    branchCard.blur(function () {
        branchCard.parent().removeClass("branch-container--active");
    });
};

/**
 * Generates a window for a FS map marker.
 * @param {any} data - Data containing location details
 * @param {string} env - The current app environment
 * @returns {string} - Location map marker in HTML format
 */
function generateFsMapCard(data, env) {
    const noImageUrl = helpers.getAssetsUrl() + "/img/maps/no-image-882x440.png";

    const mapCardContent =
        `
<div class="map-card map-card__no-image">
    <div class="map-card__content">
        <p class="map-card__cta"><a href="${data.Data.BranchURL ? data.Data.BranchURL : ''}" target="_blank">View Funeral Director <i class="icon-right-arrow"></i></a></p>
    </div>
</div>
`;
    //<p class="map-card__phone"><a href="tel:"><i class="icon-phone"></i> <span>${data.Data.BranchTel ? data.Data.BranchTel : ''}</span></a></p> - Re-add to map-card__content to show phone number
    return mapCardContent;
};

/**
 * Generates a list card for a FS location
 * @param {any} data - Data containing location details
 * @param {string} env - The current app environment
 * @returns {string} - Location card in HTML form to be used in card list
 */
function generateFsListCard(data, env) {
    const noImageUrl = helpers.getAssetsUrl() + "/img/maps/no-image-600x600.png";

    const listCardContent =
        `
<div class="branch-container">
    <a class="branch-card" data-locid="${data.Data.BranchID ? data.Data.BranchID : ''}" href="${data.Data.BranchURL ? data.Data.BranchURL : '#'}" target="_blank">
        <div class="branch-card__content">
            <h3 class="branch-card__title">${data.Data.BranchDisplayName ? data.Data.BranchDisplayName : ''}</h3>
            <p class="branch-card__copy">${data.Data.BranchAdd1 ? renderAddress(data.Data) : ''}</p>
            ${data.Distance ? '<p class="pill">' + data.Distance + ' away</p>' : ''}
            <p class="branch-card__copy u-padding-bottom--medium">
                <em>Can't come to us, we'll come to you</em>
            </p>
            <p class="branch-card__cta">
                View Funeral Director
                <i class="icon-right-arrow"></i>
            </p>
        </div>
    </a>
</div>
<hr />
`;

    return listCardContent;
};

/**
 * Generates a window for a Branches map marker.
 * @param {any} data - Data containing location details
 * @param {string} env - The current app environment
 * @returns {string} - Location map marker in HTML format
 */
function generateBranchesMapCard(data, env) {
    const noImageUrl = helpers.getAssetsUrl() + "/img/maps/no-image-882x440.png";

    const mapCardContent =
        `
<div class="map-card map-card__no-image">
    <div class="map-card__content">
        <p class="map-card__cta"><a href="${data.Dignity_BranchUrl ? data.Dignity_BranchUrl : ''}" target="_blank">View Branch <i class="icon-right-arrow"></i></a></p>
    </div>
</div>
`;

    return mapCardContent;
};


/**
 * Generates a window for a CremQS location
 * @param {any} data - Data containing location details
 * @returns {string} - Location map marker in HTML format
 */
function generateCremQsMapCard(data) {
    const mapCardContent =
        `
<div class="map-card u-size-100 ${data.Data.Crematorium.CremImages[2].ImageLocation ? '' : 'u-padding-none'}">
    ${data.Data.Crematorium.CremImages[2].ImageLocation ? '<div class="map-card__header"  style="background-image: url(' + data.Data.Crematorium.CremImages[2].ImageLocation + ');"></div>' : ''}
        <div class="map-card__content ">
            ${data.CremDistance ? '<p class="map-card__suptitle">' + data.CremDistance + '</p>' : ''}
            ${data.Data.Crematorium.CremName ? '<p class="map-card__title">' + data.Data.Crematorium.CremName + ' </p>' : ''}
            ${data.Data.Crematorium.CremFullAddress ? '<p class="map-card__poi"><i class="icon-poi"></i> <span>' + data.Data.Crematorium.CremFullAddress + '</span></p>' : ''}
            ${data.Data.Crematorium.CremTel ? '<p class="map-card__phone u-padding-bottom--small"><a href="tel:' + data.Data.Crematorium.CremTel + '"> <i class="icon-phone"></i> <span>' + data.loc_Tel + '</span></a></p > ' : ''}
            ${data.Data.Crematorium.CremURL ? '<p class="map-card__website"><i class="icon-internet"></i><a href="' + data.Data.Crematorium.CremURL + '" target="_blank"><span>View Crematorium</span></a></p>' : ''}
        <hr>                            
        <div class="grid grid__col--fixed-100 u-margin-bottom--none">
            ${data.Data.CardFeatures ? generateCremQsMarkerFeatures(data.Data.CardFeatures) : ''}
        </div>
        ${data.ctaLink1 ? '<a href="' + data.ctaLink1 + '" class="button button--icon-right button--plain-text" target="' + data.ctaTarget1 + '"' + data.ctaModal1 + '>' + data.ctaTitle1 + '</a>' : ''}
		</div>
</div>
`;

    return mapCardContent;
};

/**
 * Generates a list card for a CremQS location
 * @param {any} data - Data containing location details
 * @returns {string} - Location card in HTML form to be used in card list
 */
function generateCremQsListCard(data) {
    const listCardContent = `
<li data-locid="${data.Data.Crematorium.CremGUID ? data.Data.Crematorium.CremGUID : ''}">
    <section class="content-card content-card--alt" href="#">
        <div class="content-card__image" style="height:auto !important; background-image: url('${data.Data.Crematorium.CremImages[2].ImageLocation ? data.Data.Crematorium.CremImages[2].ImageLocation : '/assets' + Assets + '/img/crematoria/NO-IMAGE-AVAILABLE_1440x670.jpg'}')"></div>
            <div class="content-card__content u-size-100 u-padding-none u-padding-left u-padding-top">
                ${data.CremDistance ? '<p class="content-card__suptitle u-color-shade-light">' + data.CremDistance + ' away</p>' : ''}
                ${data.Data.Crematorium.CremName ? '<p class="u-text-large u-text-bold u-color-brand-a">' + data.Data.Crematorium.CremName + '</p>' : ''}
                <ul class="content-card__list">
                    ${data.Data.Crematorium.CremFullAddress ? '<li><i class="icon-poi u-color-shade-light u-text-bold u-text-larger"></i><span class="u-text-large u-margin-left">' + data.Data.Crematorium.CremFullAddress + '</span></li>' : ''}
                    ${data.Data.Crematorium.CremTel ? '<li><i class="icon-phone u-color-shade-light u-text-bold u-text-larger"></i><span class="u-text-large u-margin-left">' + data.Data.Crematorium.CremTel + '</span></li>' : ''}
                    ${data.Data.Crematorium.CremURL ? '<li><i class="icon-internet u-color-shade-light u-text-bold u-text-larger"></i><span class="u-margin-left"><a href="' + data.Data.Crematorium.CremURL + '" target="_blank" class="u-text-large u-padding-none button button--plain-text">View Crematorium</a></span></li>' : ''}
                </ul>
                <div class="grid grid__col--fixed-100 u-margin-bottom--none">
                    <div class="card-grid card-grid--6 u-align-center u-margin-bottom--none">
                    ${data.Data.CardFeatures ? data.Data.CardFeatures.map(x => { return generateCremQsFeature(x); }).join('') : ''}
                    </div>
            </div>
            <ul class="content-card__list">
                <li>
                    <p class="u-text-smaller u-margin-left">
                        If any of the details above need to be updated, please click <a href="mailto:it.testing@dignityuk.co.uk?subject=Amendment Request to Sutton Coldfield Crematorium @websiteUrl&body=Crematorium%20name%20and%20address%20details%20are%20incorrect%20and%20should%20be%3A%0D%0AContact%20details%20(phone%20numbers%2Fwebsites)%20are%20incorrect%20and%20should%20be%3A%0D%0APrice%20(total%20and%20PPM)%20are%20incorrect%20and%20should%20be%3A%0D%0ATotal%20slot%20time%20and%20service%20time%20are%20incorrect%20and%20should%20be%3A%0D%0A" class="">here</a>
                    </p>
                </li>
            </ul>
        </div>
        ${data.Data.CardFeatures ? data.Data.CardFeatures.map(x => { return generateCremQsFeatureModal(x); }).join('') : ''}
    </section>
</li>
`;

    return listCardContent;
};

/**
 * Gennerates features for both map and list cards for CremQs
 * @param {any} feature - Data containing feature details
 * @returns {string} - Formatted feature in HTML form
 */
function generateCremQsFeature(feature) {
    var featureVal = ``;
    var val;

    switch (feature.FeatureIcon) {
        case "icon-coin-pound":
            val = feature.FeatureValue ? Number(feature.FeatureValue).toLocaleString("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).split(".") : ``;
            featureVal = `<p class="u-color-brand-a u-text-largest u-text-bold"><span class="u-text-small">&pound;</span>${val[0]}<span class="u-text-small">.${val[1]}</span></p>`;
            break;

        case "icon-money-doc":
            val = feature.FeatureValue ? Number(feature.FeatureValue).toLocaleString("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).split(".") : ``;
            featureVal = `<p class="u-color-brand-a u-text-largest u-text-bold"><span class="u-text-small">&pound;</span>${val[0]}<span class="u-text-small">.${val[1]}</span></p>`;
            break;

        case "icon-clock":
            featureVal = `<p class="u-color-brand-a u-text-largest u-text-bold">${feature.FeatureValue ? feature.FeatureValue : ''}<span class="u-text-small">mins</span></p>`;
            break;

        case "icon-user":
            featureVal = `<p class="u-color-brand-a u-text-largest u-text-bold">${feature.FeatureValue ? feature.FeatureValue : ''}</p>`;
            break;

        default:
            featureVal = `<p class="u-color-brand-a u-text-bold">${feature.FeatureValue ? feature.FeatureValue : ''}</p>`;
            break;
    };

    var featureCard = `
<div class="content-card u-align-center u-padding-none">
    <div class="content-card__content u-padding-none">
        <i class="${feature.FeatureIcon ? feature.FeatureIcon : ''} u-color-shade-light u-text-medium"></i>
        <br />
        <p class="u-text-smaller u-text-bold u-color-shade-light u-margin-bottom--none">
            ${feature.FeatureTitle ? feature.FeatureTitle.toUpperCase() : ''}
            ${feature.FeatureDescriptor ? '<a href="#' + feature.FeatureName + '" class="button--plain-text" data-lity=""><i class="u-color-brand-b u-text-small icon-info-outline"></i></a>' : ''}
        </p>
        ${featureVal}
    </div>
</div>
`;

    return featureCard;
};

/**
 * Generates a modal for a CremQs feature containing the feature description
 * @param {any} feature - Data containing the feature title and description
 * @returns {string} - Feature description modal in HTML form
 */
function generateCremQsFeatureModal(feature) {
    var featureModal = `
<section class="modal" id="${feature.FeatureName ? feature.FeatureName : ''}">
    <a class="u-text-small modal__close"><i class="icon-cross"></i></a>
    <div class="modal__content">
        <p class="modal__title h3 u-color-brand-a">
            ${feature.FeatureTitle ? feature.FeatureTitle : ''}
        </p>
        <p class="modal__content">
            ${feature.FeatureDescriptor ? feature.FeatureDescriptor : ''}
        </p>
    </div>
</section>
`;

    return featureModal;
};

/**
 * Renders out features for the CremQS map markers
 * @param {any} features - Data containing the feature details
 * @returns {string} - HTML containing features to be added to a map card
 */
function generateCremQsMarkerFeatures(features) {
    var markerFeatures = ``;

    for (var i = 0; i <= features.length; i = i + 1) {
        if (i == 0 && i % 3 == 0) {
            var cardStart = `<div class="card-grid u-align-center u-margin-bottom--none">`;
            var cardContent = ``;
            for (var j = i; j < features.length; j = j + 1) {
                cardContent = cardContent + generateCremQsFeature(features[j]);
            };
            var cardEnd = `</div>`;

            markerFeatures = markerFeatures + cardStart + cardContent + cardEnd;
        }
    };

    return markerFeatures;
};

/**
 * Function to generate list card based on request type
 * @param {any} listData - The data used to render the list of cards
 * @param {any} listMode - The type of card to be rendered
 * @param {string} env - The current app environment
 * @returns {any} - Generated list cards in HTML form to be rendered onto the list page
 */
function generateLocationList(listData, listMode, env) {
    var cardContent = "";

    switch (listMode) {
        case "fs":
            cardContent = generateFsListCard(listData, env);
            break;

        case "cremqs":
            cardContent = generateCremQsListCard(listData);
            break;
    };

    return cardContent;
};

/**
 * Function to determine if address string has been populated
 * @param {any} input - The address string to be validated
 * @returns {boolean} - True if address is not null or whitespace
 */
function validateAddress(input) {
    if (input != null && input != "") {
        return true;
    };
};

/**
 * Function to form a linear address string based on the amount of address segments available
 * @param {any} address - Data containing address fields
 * @returns {string} - String containing a formed address to be put onto cards
 */
function renderAddress(address) {
    var addressString = "";

    if (validateAddress(address.BranchAdd1)) {
        addressString = addressString + address.BranchAdd1 + ", ";
    };

    if (validateAddress(address.BranchAdd2)) {
        addressString = addressString + address.BranchAdd2 + ", ";
    };

    if (validateAddress(address.BranchAdd3)) {
        addressString = addressString + address.BranchAdd3 + ", ";
    };

    if (validateAddress(address.BranchAdd4)) {
        addressString = addressString + address.BranchAdd4 + ", ";
    };

    if (validateAddress(address.BranchAdd5)) {
        addressString = addressString + address.BranchAdd5 + ", ";
    };

    if (validateAddress(address.BranchPcode)) {
        addressString = addressString + address.BranchPcode;
    };

    return addressString;
};

/**
 * Function to generate/retrieve session token for use in predictive search
 * @returns {string} - Generated token retrieved from session
 * */
function getSessionToken() {
    if (sessionStorage.getItem("locationsPredictionToken")) {
        return sessionStorage.getItem("locationsPredictionToken");
    }
    else {
        const token = new Date().getTime();
        sessionStorage.setItem("locationsPredictionToken", token);
        return token;
    }
};

/**
 * Function to store 50 location records in browser session
 * @param {any} locations - The locations object to be stored
 * @returns {boolean} - True if data is stored successfully
 */
function setLocationsCache(locations) {
    sessionStorage.setItem("locationsCache", JSON.stringify(locations));
    return true;
};

/**
 * Function to retrieve locations reocrds from browser session storage
 * @returns {any} - Locations object if available
 * */
function getLocationsCache() {
    if (sessionStorage.getItem("locationsCache")) {
        return JSON.parse(sessionStorage.getItem("locationsCache"));
    }
    else {
        return false;
    }
};

/**
 * Function to load 10 more location cards if amount of results are less than 50
 * @param {any} locationType - The type of list card required (fs/cremqs)
 * @param {any} env - The current app envrionment
 */
function loadMoreCards(locationType, env) {
    if (getLocationsCache() != false) {
        var locations = getLocationsCache();
        var currentCards = document.querySelectorAll(".map__results > li[data-locid]");
        var lastId = currentCards[currentCards.length - 1].dataset.locid;
        var nextloc;
        var newListIds = [];
        var newLocs = [];

        $("#mapLoadMore").closest("li").remove();

        switch (locationType) {
            case "fs":
                for (let i = 0; i < locations.length; i = i + 1) {
                    if (locations[i].Data.BranchID == lastId) {
                        nextloc = i + 1;
                    }
                };

                for (let j = nextloc; j < (nextloc + 6); j = j + 1) {
                    newListIds.push(locations[j].Data.BranchID);
                };

                getLocationData(newListIds.toString(), env).done(function (result) {
                    for (let k = nextloc; k < (nextloc + 6); k = k + 1) {
                        var newLoc = locations[k];

                        result.map(x => {
                            if (x.Data.BranchID == newLoc.Data.BranchID) {
                                newLoc = x;
                            };
                        });
                        newLocs.push(newLoc);
                    };

                    for (let k1 = 0; k1 < newLocs.length; k1 = k1 + 1) {
                        var newCard = generateLocationList(newLocs[k1], locationType);
                        $(".map__results").append(newCard);
                    };

                    if (nextloc < 12) {
                        $(".map__results").append(`<li class="u-align-center"><a class="button" id="mapLoadMore" href="#">Load more results</a></li>`);
                        $("#mapLoadMore").click(function (e) {
                            e.preventDefault();
                            loadMoreCards(locationType, env);
                        });
                    };
                });
                break;

            case "cremqs":
                for (let i = 0; i < locations.length; i = i + 1) {
                    if (locations[i].Data.Crematorium.CremGUID == lastId) {
                        nextloc = i + 1;
                    }
                };

                for (let j = nextloc; j < (nextloc + 10); j = j + 1) {
                    newListIds.push(locations[j].Data.Crematorium.CremGUID);
                };

                getLocationData(newListIds.toString(), env).done(function (result) {
                    for (let k = nextloc; k < (nextloc + 10); k = k + 1) {
                        var newLoc = locations[k];

                        result.map(x => {
                            if (x.Crematorium.CremGUID == newLoc.Data.Crematorium.CremGUID) {
                                newLoc.Data = x;
                            };
                        });
                        newLocs.push(newLoc);
                    };

                    for (let k1 = 0; k1 < newLocs.length; k1 = k1 + 1) {
                        var newCard = generateLocationList(newLocs[k1], locationType);
                        $(".map__results").append(newCard);
                    };

                    if (nextloc < 40) {
                        $(".map__results").append(`<li class="u-align-center"><a class="button" id="mapLoadMore" href="#">Load more results</a></li>`);
                        $("#mapLoadMore").click(function (e) {
                            e.preventDefault();
                            loadMoreCards(locationType, env);
                        });
                    };
                });
                break;
        };
    }
};


function getParameterByName(name) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};